<template>
  <div class="contact view-container">
    <MainCard
      :data="contactData"
      class="main-card"/>
    <div class="contact-box">
      <p class="title">{{ $t('联系我们 ') }}</p>
      <div class="contact-info">
        <p class="text">{{ $t('联系邮箱') }}</p>
        <a
          class="email"
          href="mailto:wangyang@shenzhouxing.com.cn">
          wangyang@shenzhouxing.com.cn
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import image from '@/assets/image/contact/main.png'
import h5Image from '@/assets/image/contact/main-h5.png'
export default {
  data () {
    return {
      contactData: {
        title: '联系我们 ',
        subtitle: 'Contact Us',
        image,
        h5Image
      }
    }
  },
  methods: {
  }
}
</script>

<style scoped lang="less">
.contact {
  padding-top: 50px;
  .main-card {
    margin-bottom: 50px;
  }
  .contact-box {
    width: 100%;
    max-width: 1000px;
    height: 362px;
    margin: 0 auto;
    padding-top: 10px;
    background-image: url('../../assets/image/contact/conact-bg.png');
    background-size: 100% 100%;
    .title {
      font-size: 36px;
      color: #333333;
      line-height: 36px;
      text-align: center;
    }
    .contact-info {
      display: flex;
      justify-content: center;
      margin-top: 255px;
      text-align: center;
      font-size: 16px;
      line-height: 16px;
      .text {
        color: #1D2130;
        margin-right: 5px;
      }
      .email {
        color: #0056C7;
      }
    }
  }
}
@media (max-width: @sm-width) {
  .contact {
    padding-top: 15px;
    .main-card {
      margin-bottom: 15px;
    }
    .contact-box {
      width: calc(100% - 40px);
      height: 200px;
      margin: 0 auto;
      padding-top: 15px;
      background-image: url('../../assets/image/contact/conact-bg-h5.png');
      background-size: 100% 100%;
      .title {
        display: none;
      }
      .contact-info {
        display: block;
        margin-top: 195px;
        text-align: center;
        font-size: 14px;
        line-height: 23px;
        .text {
          font-size: 16px;
          color: #1D2130;
          margin-right: 0;
        }
        .email {
          color: #0056C7;
        }
      }
    }
  }
}
</style>
